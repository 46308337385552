.map-wrapper {
  height: 100%;
}

.map-nav-wrapper {
  width: 100%;
  height: 6%;
  min-height: 50px;
  border-radius: 0;
  z-index: 30000;
  cursor: default;
  display: flex;
  background: #45b2a5;
  color: white;
  align-items: center;
  justify-content: center;
}

.map-nav-problem-icon {
  margin-right: 10px;
}

.map-popup-wrapper {
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  overflow: scroll;
  text-align: center;
}
.map-popup-guide {
  width: 100%;
  object-fit: cover;
  border: unset;
}

.map-popup-button-wrapper {
  position: fixed;
  left: 3vw;
  top: 2vw;
  z-index: 10;
}

@media screen and (min-width: 768px) {
  .map-popup-guide {
    width: 60%;
  }
  .map-popup-guide {
    border-color: rgb(167, 167, 167);
    border-style: solid;
    border-width: 0px 1px 0px 1px;
  }
  .map-popup-button-wrapper {
    top: 2vw;

    left: 22vw;
  }
}
