.unauthorized-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;

  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  font-weight: 300;
  color: #484848;
}

.unauthorized-bike {
  width: 70%;
}

@media (min-width: 768px) {
  .unauthorized-wrapper {
    font-size: 2rem;
  }
  .unauthorized-bike {
    width: 30%;
  }
}
