.document-wrapper {
  position: relative;
  height: 120vh;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;


}


.link-tuto {
  position:absolute;
  top:65%;

  width: 65%;
  height: 10%;
  color: transparent;
  margin: 0;
  font-size: 1.4vw;
  background-color: transparent;
  transform: translate(-50%, 0px);
}


@media (min-width: 768px) {
  .document-wrapper {
    height: 200vh;
  }
}

@media (max-width: 321px) {
  .document-wrapper {
    height: 220vw;
  }
}

@media (max-width: 425px) {
  .document-wrapper {
    height: 220vw;
  }
}
